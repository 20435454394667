import request from '@/utils/request'
const baseURL = process.env.VUE_APP_BASE_API

export function fetchWrongContractList(params) {
    return request({
        url: baseURL + '/contractUnsuccess/list',
        method: 'GET',
        params: params
    })
}

export function fetchWrongContractDetail(params) {
    return request({
        url: baseURL + '/contractUnsuccess/detail',
        method: 'GET',
        params: params
    })
}
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {fetchWrongContractDetail} from "@/api/wrongContract";
import {Message} from "element-ui";

export default {
    name: 'WrongContractDetail',
    beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.usersOpened = from.fullPath.includes('CheckDocument')
		})
	},
    data() {
        return {
            items: [],
            // loanType: ['ไฟแนนซ์', 'ที่ดิน', 'p_loan', 'โอนรถแล้ว'],
            // causeOption: ['','เอกสารเซ็นไม่ครบ', 'กุญเเจสำรอง', 'ใบตรวจสภาพ', 'คู่มือ'],
            isLoading: false,
			// dialogImageUrl: '',
			// dialogVisible: false,
			// temp: {
			// 	attachments: []
			// },
            contractParam: {
                contractId: this.$route.params.id
            },
            FormData:{
                loanType: "",
                contractId: "",
                startDateContract: "",
                balance: "",
                nameCustomer: "",
                idCard: "",
                vehicleNumber: "",
                docStatusId: "",
                carStatusId: "",
                docCause: "",
                carCause: "",
                docImageUrl: [],
                carImageUrl: [],
                docRemark: "",
                carRemark: ""
            },
            url: ['https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg', 
                'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
            ],
            
            srcList: [
                'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg', 
                'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
            ],
            active: 1
        }
    },
    // computed: {
    //     userLoad() {
	// 		const id = this.$route.params.id
    //         console.log('log : ' + id);
	// 		return this.items.find(user => user.contractNumber == id)
	// 	},
    // },

    async mounted() {
        this.$store.state.loading = true
        await this.getWrongContractDetail();
		this.$store.state.loading = false

    },

    methods: {
        getWrongContractDetail () {
            console.log('contractParam ==> ', this.contractParam);

            fetchWrongContractDetail(this.contractParam).then(res => {
                this.items = []
                const check = res.header;
				const data = res.body;
                if (check.error == 'N') {
                    this.FormData.loanType = data[0].contract_type_name;
                    this.FormData.contractId = data[0].contract_id;
                    this.FormData.startDateContract = data[0].start_date_contract;
                    this.FormData.balance = data[0].balance;
                    this.FormData.nameCustomer = data[0].name_customer;
                    this.FormData.idCard = data[0].id_card;
                    this.FormData.vehicleNumber = data[0].vehicle_number;
                    this.FormData.docStatusId = data[0].doc_status_id;
                    this.FormData.carStatusId = data[0].car_status_id;
                    this.FormData.docCause = data[0].docCause.join(",");
                    this.FormData.carCause = data[0].carCause.join(",");
                    this.FormData.docImageUrl = data[0].docImageUrl;
                    this.FormData.carImageUrl = data[0].carImageUrl;
                    this.FormData.docRemark = data[0].doc_remark;
                    this.FormData.carRemark = data[0].car_remark;

                    this.items = data[0];

                    console.log('data ==> ', this.FormData);
                }
            }).catch(error => {
				Message({
					message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
					type: 'error',
					duration: 5 * 1000,
				})
				this.errorMsg = error
				console.log('error :', error)
			})
        },
        
        goBack() {
			this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/step5/WrongContract'})
		}
    },
}
